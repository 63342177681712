import * as bootstrap from 'bootstrap';

import hotkeys from 'hotkeys-js';

document.addEventListener('turbo:load', function() {
  // Set focus to site-wide search
  hotkeys('/', function(event, _handler) {
    event.preventDefault();
    // Ignore shortcuts if a modal is open
    if (document.querySelector('body.modal-open')) {
      return;
    }
    return document.getElementById('site_search_query').focus();
  });
  // Open or close the Offcanvas Left Site Sidebar menu
  return hotkeys('m', function(event, _handler) {
    var siteSidebarOffcanvas;
    event.preventDefault();
    // Ignore shortcuts if a modal is open
    if (document.querySelector('body.modal-open')) {
      return;
    }
    siteSidebarOffcanvas = bootstrap.Offcanvas.getOrCreateInstance('.js-menu-shortcut');
    if (siteSidebarOffcanvas._element.classList.contains('show')) {
      return siteSidebarOffcanvas.hide();
    } else {
      return siteSidebarOffcanvas.show();
    }
  });
});
